import * as amplitude from '@amplitude/analytics-browser';
import { Fragment, ReactNode, useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

interface Props {
  children: ReactNode;
}

export const AnalyticsProvider = ({ children }: Props) => {
  const handleAccept = () => {
    amplitude.init(process.env.REACT_APP_ANALYTICS_AMPLITUDE_ID ?? '', { autocapture: true });
  };

  const handleReject = () => {
    //
  };

  useEffect(() => {
    if (getCookieConsentValue('userCookieConsent') === 'true') {
      amplitude.init(process.env.REACT_APP_ANALYTICS_AMPLITUDE_ID ?? '', { autocapture: true });
    }
  });

  return (
    <Fragment>
      {children}

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton
        onAccept={handleAccept}
        onDecline={handleReject}
        cookieName="userCookieConsent"
        expires={365}
      >
        This website uses cookies to improve your experience. You can accept or decline.
      </CookieConsent>
    </Fragment>
  );
};
