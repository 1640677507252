import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { ExternalLink } from 'src/elements/Link';
import { Svg } from 'src/elements/Svg';

const TIKTOK_URL = 'https://www.tiktok.com/@aibae.me?_t=8raKrG7bPU7&_r=1';
const TWITTER_URL = 'https://x.com/aibae_me?s=21';
const INSTAGRAM_URL = 'https://www.instagram.com/aibae.me?igsh=c242YThwMjRlamp6';

export const SocialIcons = () => {
  return (
    <FlexGroup $gap={8} $flex={0}>
      <Spacer />

      <ExternalLink $clear href={TIKTOK_URL}>
        <Svg name="tiktok" $size="bodyLarge" $color="darkPrimary" />
      </ExternalLink>

      <ExternalLink $clear href={TWITTER_URL}>
        <Svg name="twitter" $size="bodyLarge" $color="darkPrimary" />
      </ExternalLink>

      <ExternalLink $clear href={INSTAGRAM_URL}>
        <Svg name="instagram" $size="bodyLarge" $color="darkPrimary" />
      </ExternalLink>
    </FlexGroup>
  );
};
