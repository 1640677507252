import { animated, useSpring } from '@react-spring/web';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import backgroundUrl from 'src/assets/images/background.png';
import { DialogProvider } from 'src/providers/DialogProvider';
import { currentUserAtom } from 'src/utils/atoms/state';
import { useViewport } from 'src/utils/hooks/useViewport';

import { Footer } from '../MainLayout/Footer';
import { CardActions } from './CardActions';

const Container = styled.div`
  display: flex;

  @media only screen and (max-width: 1032px) {
    flex-direction: column-reverse;
    padding-bottom: 16px;
  }
`;

const Content = styled.main<{ $imageUrl: string }>`
  display: flex;
  background-image: url(${({ $imageUrl }) => $imageUrl}), ${({ theme }) => theme.colors.bgBrand};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 8px;
  border-radius: 16px;

  @media only screen and (min-width: 1033px) {
    flex: 1;
    min-height: 890px;
    height: calc(100dvh - 16px);
  }

  @media only screen and (max-width: 1032px) {
    margin: 4px;
  }
`;

export const CardLayout = () => {
  const { pathname } = useLocation();
  const { isLaptopUp } = useViewport();
  const { handle = '' } = useParams();
  const { soulmateId: currentUserSoulmateId } = useAtomValue(currentUserAtom);

  // Remove the starting '@' from the handle.
  const soulmateId = handle.substring(1);

  const heightAnimation = useSpring({
    from: { height: '500px' },
    to: { height: '751px' },
    config: { tension: 210, friction: 20 },
  });

  // Scroll to top on nav.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <DialogProvider />

      <CardActions />

      {!isLaptopUp && (
        <div style={{ padding: 8 }}>
          <Footer showDelete={currentUserSoulmateId === soulmateId} />
        </div>
      )}

      <Content $imageUrl={backgroundUrl} as={animated.main} style={isLaptopUp ? undefined : heightAnimation}>
        <Outlet />
      </Content>
    </Container>
  );
};
