import { useAtom } from 'jotai';

import { IconButton } from 'src/elements/Icon';
import { waitlistMusicPlayingAtom } from 'src/utils/atoms/ui';

export const MusicButton = () => {
  const [musicPlaying, setMusicPlaying] = useAtom(waitlistMusicPlayingAtom);

  return (
    <IconButton
      name={musicPlaying ? 'volume_up' : 'volume_off'}
      label={musicPlaying ? 'Pause music' : 'Play music'}
      onClick={() => setMusicPlaying(!musicPlaying)}
      $color="darkPrimary"
      $variant="clear"
    />
  );
};
