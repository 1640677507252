import { Link } from 'react-router-dom';

import { ReactComponent as LogoPillSvg } from 'src/assets/icons/aibae-logo-wide.svg';
import { Button } from 'src/elements/Button';
import { Icon } from 'src/elements/Icon';

interface Props {
  width?: number;
  backButtonTo?: string;
  showGenerateButton?: boolean;
}

export const LogoPill = ({ width = 121, backButtonTo, showGenerateButton = false }: Props) => {
  if (backButtonTo) {
    return (
      <Link to={backButtonTo}>
        <Button $variant="secondary">
          <Icon name="arrow_back_ios" label="Back" $size="bodySmall" />
          Back
        </Button>
      </Link>
    );
  }

  if (showGenerateButton) {
    return (
      <Link to="/">
        <Button $variant="primary">Create your own!</Button>
      </Link>
    );
  }

  return (
    <div style={{ width }}>
      <Link to="/">
        <LogoPillSvg style={{ height: width * 0.368, width }} />
      </Link>
    </div>
  );
};
