import { atom } from 'jotai';
import { FunctionComponent } from 'react';

import { persistentAtom } from '../helpers/atoms/persistentAtom';

export const activeDialogAtom = atom<{ Component: FunctionComponent<any> | null; props: any }>({
  Component: null,
  props: {},
});

export const onboardingStateAtom = persistentAtom<{ dismissed: boolean; step: number }>('onboardingState', {
  dismissed: false,
  step: 1,
});

export const waitlistMusicPlayingAtom = atom(false);
