import styled from 'styled-components';

import { SoulmateDetails } from 'src/api/soulmate/api';
import { Image } from 'src/components/ui/Image';
import { Link } from 'src/elements/Link';
import { PreTitle, Text } from 'src/elements/Text';
import { useHideDialog } from 'src/utils/hooks/useDialog';

interface Props {
  soulmate: SoulmateDetails;
}

const Container = styled(Link)<{ $rarity: 'Common' | 'Rare' | 'Epic' | 'Unhinged' }>`
  width: calc(50% - 20px);
  padding: 8px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  ${({ $rarity }) => {
    switch ($rarity) {
      case 'Common':
        return `background: #090916;`;
      case 'Rare':
        return `background: linear-gradient(167.24deg, #090916 0.04%, #E93275 100%);`;
      case 'Epic':
        return `background: linear-gradient(167.24deg, #090916 0.04%, #32E2C4 50.02%, #E93275 100%);`;
      case 'Unhinged':
        return `background: linear-gradient(167.24deg, #C8FF3F 0.04%, #32E2C4 33.03%, #E93275 66.02%, #FBF5C4 100%);`;
    }
  }}
`;

const NameConatiner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin-bottom: 4px;
`;

const ImageContainer = styled.div`
  border-radius: 14px;
  overflow: hidden;
`;

export const CardPreview = ({ soulmate }: Props) => {
  const hide = useHideDialog();

  return (
    <Container to={`/@${soulmate.handle}`} onClick={hide} $rarity={soulmate.rarity}>
      <NameConatiner>
        <PreTitle
          $uppercase
          $size="bodyMedium"
          $color={soulmate.rarity === 'Unhinged' ? 'darkPrimary' : 'lightPrimary'}
        >
          {soulmate.name}
        </PreTitle>

        <Text $size="bodySmall" $color={soulmate.rarity === 'Unhinged' ? 'darkSecondary' : 'lightSecondary'}>
          @{soulmate.handle}
        </Text>
      </NameConatiner>

      <ImageContainer>
        <Image src={soulmate.imageUrl} alt={soulmate.name} ratio={1} />
      </ImageContainer>
    </Container>
  );
};
