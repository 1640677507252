import { params } from 'src/utils/helpers/query/params';

export const queryKeys = {
  // /soulmate
  soulmateProgress: params('SoulmateProgress'),
  soulmate: params('Soulmate'),
  soulmates: params('Soulmates'),
  // /waitlist
  waitlist: params('Waitlist'),
};
