import * as RadixPopover from '@radix-ui/react-popover';
import { useAtom } from 'jotai';
import { ReactNode } from 'react';
import { styled } from 'styled-components';

import { slideAndFade } from 'src/assets/keyframes';
import { animationTiming } from 'src/assets/mixins';
import { Image } from 'src/components/ui/Image';
import { Button } from 'src/elements/Button';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { Text, TitleMedium } from 'src/elements/Text';
import { onboardingStateAtom } from 'src/utils/atoms/ui';

interface Props {
  imageUrl: string;
  title: string;
  subtitle?: string;
  trigger: ReactNode;
  side?: 'top' | 'bottom' | 'right' | 'left';
  step: number;
}

const Content = styled(RadixPopover.Content)`
  padding: 16px;
  background: ${({ theme }) => theme.colors.bgLight};
  border-radius: 24px;
  border: 2px solid ${({ theme }) => theme.colors.bgDark};
  width: 500px;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  animation: ${slideAndFade} 0.4s;
  will-change: transform, opacity;

  @media only screen and (max-width: 516px) {
    margin-left: 4px;
    margin-right: 4px;
    width: calc(100dvw - 8px);
  }

  ${animationTiming}
`;

const Arrow = styled(RadixPopover.Arrow)`
  fill: ${({ theme }) => theme.colors.bgDark};
`;

const MAX_STEPS = 6;

export const OnboardingPopover = ({ imageUrl, title, subtitle, step, trigger, side = 'top' }: Props) => {
  const [{ dismissed, step: currentStep }, setOnboardingState] = useAtom(onboardingStateAtom);

  return (
    <RadixPopover.Root open={currentStep === step && !dismissed}>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>

      <RadixPopover.Portal>
        <Content side={side} align="center" sideOffset={0} alignOffset={0}>
          <Arrow />

          <Image src={imageUrl} alt="Onboarding step" ratio={1.372} />

          <FlexGroup $vertical $flex={0} $gap={2} style={{ alignItems: 'center' }}>
            <TitleMedium $color="brandSecondary" $uppercase $centered>
              {title}
            </TitleMedium>

            {subtitle && <Text $color="darkSecondary">{subtitle}</Text>}
          </FlexGroup>

          {step === MAX_STEPS ? (
            <Button $fullwidth $size="large" onClick={() => setOnboardingState({ dismissed: true, step: 1 })}>
              DONE
            </Button>
          ) : (
            <FlexGroup $gap={16}>
              <Text $color="darkSecondary">
                step {step} of {MAX_STEPS}
              </Text>

              <Spacer />

              <Button $variant="text" $size="large" onClick={() => setOnboardingState({ dismissed: true, step: 1 })}>
                SKIP IT
              </Button>

              <Button $size="large" onClick={() => setOnboardingState({ dismissed, step: step + 1 })}>
                NEXT
              </Button>
            </FlexGroup>
          )}
        </Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};
