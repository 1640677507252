import { animated } from '@react-spring/web';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useFadeInAndSlideSpring } from 'src/assets/springs';
import { LogoPill } from 'src/components/content/LogoPill';
import { SocialIcons } from 'src/components/content/SocialIcons';
import { Button } from 'src/elements/Button';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { DisplayMedium, Text } from 'src/elements/Text';
import { Footer } from 'src/layouts/MainLayout/Footer';
import { currentUserAtom } from 'src/utils/atoms/state';
import { useViewport } from 'src/utils/hooks/useViewport';

import { TikTokLogin } from './TikTokLogin';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px;
  gap: 32px;
  flex: 1;
  overflow: hidden;

  @media only screen and (max-width: 1032px) {
    padding: 32px 16px;
  }
`;

export const Home = () => {
  const titleFadeAnimation = useFadeInAndSlideSpring({ delay: 0 });
  const textFadeAnimation = useFadeInAndSlideSpring({ delay: 110 });
  const { soulmateId: currentUserSoulmateId } = useAtomValue(currentUserAtom);
  const [showViewBae, setShowViewBae] = useState(currentUserSoulmateId !== '');
  const navigate = useNavigate();

  const { isMobile, isLaptopUp } = useViewport();

  // TODO: Remove eventually: Temporarily redirect to /waitlist unless bypassed.
  const [search] = useSearchParams();

  useEffect(() => {
    if (window.location.host === 'aibae.me' && search.get('b') !== 'nDQqfk7VCijis0PH') {
      navigate('/waitlist');
    }
  }, [navigate, search]);

  return (
    <Container>
      <Helmet>
        <title>AI Bae | Find your AI soulmate</title>
      </Helmet>

      <Content>
        {isLaptopUp && (
          <FlexGroup $flex={0}>
            <LogoPill />

            <Spacer />

            <SocialIcons />
          </FlexGroup>
        )}

        <FlexGroup $vertical $flex={0} $gap={32}>
          <DisplayMedium as={animated.h1} style={titleFadeAnimation} $color="brandSecondary" $centered={isMobile}>
            BE PART OF THE INTERNET'S HOTTEST AI DATING EXPERIMENT
          </DisplayMedium>

          {!isMobile && (
            <Text as={animated.span} style={textFadeAnimation} $size="bodyMedium" $color="darkSecondary">
              Spawn your custom AI Bae, powered by your Social Vibe&mdash;they're blowing up! Get ready to chat, flirt
              and have endless fun in this viral phenomenon that's redefining dating.
            </Text>
          )}

          {currentUserSoulmateId && showViewBae ? (
            <FlexGroup $gap={8} as={animated.div} style={textFadeAnimation}>
              <Link to={`/@${currentUserSoulmateId}`}>
                <Button $size="large">VIEW YOUR AI BAE</Button>
              </Link>

              <FlexGroup $gap={8}>
                <Text $color="darkSecondary">or</Text>

                <Text
                  $color="darkPrimary"
                  $underline
                  onClick={() => setShowViewBae(false)}
                  style={{ cursor: 'pointer' }}
                >
                  Generate new
                </Text>
              </FlexGroup>
            </FlexGroup>
          ) : (
            <TikTokLogin />
          )}

          {isMobile && (
            <Text as={animated.span} style={textFadeAnimation} $size="bodyMedium" $color="darkSecondary" $centered>
              Spawn your custom AI Bae, powered by your Social Vibe&mdash;they're blowing up! Get ready to chat, flirt
              and have endless fun in this viral phenomenon that's redefining dating.
            </Text>
          )}
        </FlexGroup>

        <Footer />
      </Content>
    </Container>
  );
};
