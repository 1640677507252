import { Provider as RadixTooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { queryClient } from 'src/api/queryClient';
import { GlobalStyle } from 'src/assets/GlobalStyle';
import { AnalyticsProvider } from 'src/providers/AnalyticsProvider';
import { ThemeProvider } from 'src/providers/ThemeProvider';
import { reportWebVitals } from 'src/utils/debug/reportWebVitals';

import { router } from './router';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <AnalyticsProvider>
      <JotaiProvider>
        <RadixTooltipProvider delayDuration={100}>
          <ThemeProvider>
            <RouterProvider router={router} />

            <GlobalStyle />
          </ThemeProvider>
        </RadixTooltipProvider>

        <ReactQueryDevtools initialIsOpen={false} />
      </JotaiProvider>
    </AnalyticsProvider>
  </QueryClientProvider>
);

reportWebVitals(console.log);
