import { css, styled } from 'styled-components';

import { spin } from 'src/assets/keyframes';
import { animationTiming } from 'src/assets/mixins';

interface Props {
  $variant?: 'primary' | 'secondary' | 'outline' | 'text' | 'delete';
  $size?: 'large' | 'medium' | 'small';
  $loading?: boolean;
  $fullwidth?: boolean;
  $centered?: boolean;
  $round?: boolean;
}

export const Button = styled.button.attrs((props) => ({
  type: 'button',
  role: 'button',
  'aria-disabled': props.disabled ? 'true' : undefined,
  ...props,
}))<Props>`
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  font-family: 'Grandstander', 'Roboto', sans-serif;
  letter-spacing: -2px;
  line-height: 100%;
  font-weight: 500;
  border-radius: 1000px;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  gap: 4px;

  transition: background 0.2s, opacity 0.2s, color 0.2s, fill 0.2s, box-shadow 0.2s;
  border-radius: 100px;

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.secondary};
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.6 !important;
  }

  ${({ $centered }) => {
    if ($centered) {
      return css`
        justify-content: center;
      `;
    }
  }}

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: 24px
          font-size: ${({ theme }) => theme.fontSizes.bodySmall};
          padding: 0 4px;
        `;
      case 'large':
        return css`
          height: 56px;
          font-size: ${({ theme }) => theme.fontSizes.bodyLarge};
          gap: 8px;
          padding: 0 24px;
        `;
      default:
        return css`
          height: 36px;
          padding: 0 12px;
        `;
    }
  }}

  ${({ $round }) => {
    if ($round) {
      return css`
        padding: 14px;
      `;
    }
  }}

  ${({ $variant }) => {
    switch ($variant) {
      case 'outline':
        return css`
          color: ${({ theme }) => theme.textColors.lightPrimary};
          fill: ${({ theme }) => theme.textColors.lightPrimary};
          background: transparent;
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.primaryDark};

          &:hover {
            color: ${({ theme }) => theme.textColors.brandPrimaryDark};
            fill: ${({ theme }) => theme.textColors.brandPrimaryDark};
          }

          &:active {
            background: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.textColors.darkPrimary};
            fill: ${({ theme }) => theme.textColors.darkPrimary};
          }

          &:after {
            border-color: ${({ theme }) => theme.textColors.lightPrimary};
          }
        `;
      case 'text':
        return css`
          color: ${({ theme }) => theme.textColors.darkSecondary};
          fill: ${({ theme }) => theme.textColors.darkSecondary};
          padding: 0;
          height: auto;

          &:hover,
          &:active {
            color: ${({ theme }) => theme.textColors.darkPrimary};
            fill: ${({ theme }) => theme.textColors.darkPrimary};
          }

          &:after {
            border-color: ${({ theme }) => theme.textColors.darkSecondary};
          }
        `;
      case 'delete':
        return css`
          color: ${({ theme }) => theme.textColors.error};
          fill: ${({ theme }) => theme.textColors.error};
          background: transparent;
          border: 3px solid ${({ theme }) => theme.colors.error};
          border-bottom-width: 6px;

          &:hover {
            background: ${({ theme }) => theme.colors.primaryLight};
          }

          &:active {
            border-bottom-width: 3px;
            border-top-width: 6px;
          }

          &:after {
            border-color: ${({ theme }) => theme.textColors.darkPrimary};
          }
        `;
      case 'secondary':
        return css`
          color: ${({ theme }) => theme.textColors.darkPrimary};
          fill: ${({ theme }) => theme.textColors.darkPrimary};
          background: ${({ theme }) => theme.colors.bgLightGrey};
          border: 3px solid ${({ theme }) => theme.colors.bgDark};
          border-bottom-width: 6px;

          &:hover {
            background: ${({ theme }) => theme.colors.bgLight};
          }

          &:active {
            border-bottom-width: 3px;
            border-top-width: 6px;
          }

          &:after {
            border-color: ${({ theme }) => theme.textColors.darkPrimary};
          }
        `;
      default:
        return css`
          color: ${({ theme }) => theme.textColors.darkPrimary};
          fill: ${({ theme }) => theme.textColors.darkPrimary};
          background: ${({ theme }) => theme.colors.primary};
          border: 3px solid ${({ theme }) => theme.colors.primaryDark};
          border-bottom-width: 6px;

          &:hover {
            background: ${({ theme }) => theme.colors.primaryLight};
          }

          &:active {
            border-bottom-width: 3px;
            border-top-width: 6px;
          }

          &:after {
            border-color: ${({ theme }) => theme.textColors.darkPrimary};
          }
        `;
    }
  }}

  ${({ $loading }) => {
    if ($loading) {
      return css`
        pointer-events: none;
        color: transparent;
        fill: transparent;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: calc(50% - 6px);
          left: calc(50% - 6px);
          height: 12px;
          width: 12px;
          border-width: 2px;
          border-style: solid;
          border-radius: 1000px;
          border-right-color: transparent;
          animation: ${spin} 1s infinite;
          will-change: transform;

          ${animationTiming}
        }
      `;
    }
  }}

  ${({ $fullwidth }) => {
    if ($fullwidth) {
      return css`
        width: 100%;
        justify-content: center;
      `;
    }
  }}
`;

export const TikTokButton = styled(Button)`
  background: ${({ theme }) => theme.colors.bgLight};
  border: 2px solid ${({ theme }) => theme.colors.darkBorderDefault};
  border-bottom-width: 4px;

  &:before {
    content: unset;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.bgDark};
    border-color: ${({ theme }) => theme.colors.bgDark};
  }

  &:active {
    border-bottom-width: 2px;
    border-top-width: 4px;
    background: ${({ theme }) => theme.colors.bgDarkGrey};
  }
`;

export const TwitterButton = styled(Button)`
  background: ${({ theme }) => theme.colors.bgDark};

  &:before {
    content: unset;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.bgDarkGrey};
  }

  &:active {
    background: ${({ theme }) => theme.colors.bgDarkGrey};
  }
`;
