import { Fragment, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { LogoPill } from 'src/components/content/LogoPill';
import { SocialIcons } from 'src/components/content/SocialIcons';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { DialogProvider } from 'src/providers/DialogProvider';

import { Footer } from '../MainLayout/Footer';

const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 128px auto;
  width: 1000px;

  @media only screen and (max-width: 1032px) {
    width: calc(100% - 32px);
    margin: 32px 16px;
  }
`;

export const ClearLayout = () => {
  const { pathname } = useLocation();

  // Scroll to top on nav.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fragment>
      <DialogProvider />

      <Content>
        <FlexGroup $flex={0} style={{ marginBottom: 64 }}>
          <LogoPill />

          <Spacer />

          <SocialIcons />
        </FlexGroup>

        <Outlet />

        <Footer />
      </Content>
    </Fragment>
  );
};
