import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useGetSoulmatesQuery } from 'src/api/soulmate/query';
import { Copy } from 'src/components/ui/Copy';
import { Dialog, DialogContent } from 'src/components/ui/Dialog';
import { Field } from 'src/components/ui/Field';
import { FlexGroup, Spacer } from 'src/elements/FlexGroup';
import { Icon } from 'src/elements/Icon';
import { PreTitle, Text } from 'src/elements/Text';

import { CardPreview } from './CardPreview';

const LatestContainer = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const DiscoverDialog = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const inviteUrl = `${window.location.protocol}//${window.location.host}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debounce((value: string) => setSearchTerm(value), 300),
    []
  );

  useEffect(() => {
    onSearch(searchValue);
  }, [searchValue, onSearch]);

  const { isLoading, isError, data: soulmates } = useGetSoulmatesQuery({ search: searchTerm });

  return (
    <Dialog fullheight>
      {() => (
        <DialogContent title="DISCOVER OTHER BAES" description="Memeify and react!">
          <FlexGroup $vertical $flex={0} $gap={32}>
            <Field
              name="search"
              placeholder="@username"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              icon={<Icon name="search" label="Search" $color="darkSecondary" $size="bodyLarge" />}
            />

            {!searchTerm && (
              <FlexGroup $vertical $flex={0} $gap={12}>
                <FlexGroup>
                  <PreTitle $color="darkPrimary" $size="bodyLarge">
                    INVITE A FRIEND
                  </PreTitle>

                  <Spacer />

                  <Text $color="darkSecondary" $size="bodyMedium">
                    Share this link
                  </Text>
                </FlexGroup>

                <Field name="share" value={inviteUrl} readOnly suffix={<Copy value={inviteUrl} />} />
              </FlexGroup>
            )}

            <FlexGroup $vertical $flex={0} $gap={12}>
              <PreTitle $color="darkPrimary" $size="bodyLarge">
                {searchTerm ? "YOUR FRIENDS' BAES" : 'LATEST BAES'}
              </PreTitle>

              <LatestContainer>
                {isLoading && (
                  <Icon name="progress_activity" label="Loading" $color="brandSecondary" $size="titleSmall" $spin />
                )}

                {isError && <Text $color="darkSecondary">There was an error fetching the latest Baes</Text>}

                {soulmates?.length === 0 && <Text $color="darkSecondary">No Baes found</Text>}

                {soulmates?.map((soulmate) => (
                  <CardPreview key={soulmate.imageUrl} soulmate={soulmate} />
                ))}
              </LatestContainer>
            </FlexGroup>
          </FlexGroup>
        </DialogContent>
      )}
    </Dialog>
  );
};
