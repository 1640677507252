import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { SoulmateStatus } from 'src/api/soulmate/api';
import { useGetSoulmateProgressQuery, useGetSoulmateQuery } from 'src/api/soulmate/query';
import onboardingStepOneUrl from 'src/assets/images/onboarding-step-1.png';
import onboardingStepTwoUrl from 'src/assets/images/onboarding-step-2.png';
import onboardingStepThreeUrl from 'src/assets/images/onboarding-step-3.png';
import onboardingStepFourUrl from 'src/assets/images/onboarding-step-4.png';
import onboardingStepFiveUrl from 'src/assets/images/onboarding-step-5.png';
import onboardingStepSixUrl from 'src/assets/images/onboarding-step-6.png';
import { OnboardingPopover } from 'src/components/content/OnboardingPopover';
import { ChatDialog } from 'src/components/dialogs/ChatDialog/ChatDialog';
import { ConnectSocialsDialog } from 'src/components/dialogs/ConnectSocialsDialog/ConnectSocialsDialog';
import { DiscoverDialog } from 'src/components/dialogs/DiscoverDialog/DiscoverDialog';
import { MemeifyDialog } from 'src/components/dialogs/MemeifyDialog/MemeifyDialog';
import { ShareDialog } from 'src/components/dialogs/ShareDialog/ShareDialog';
import { Button } from 'src/elements/Button';
import { Svg } from 'src/elements/Svg';
import { useDialog } from 'src/utils/hooks/useDialog';
import { useViewport } from 'src/utils/hooks/useViewport';
import { useAtomValue } from 'jotai';
import { currentUserAtom } from 'src/utils/atoms/state';

const Container = styled.aside`
  display: flex;
  width: 160px;

  @media only screen and (max-width: 1032px) {
    width: 100%;
    height: 88px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 64px 0;
  width: 100%;

  @media only screen and (max-width: 1032px) {
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    padding: 16px 8px;
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 0;
    background: ${({ theme }) => theme.colors.bgLight};
  }
`;

export const CardActions = () => {
  const { handle = '' } = useParams();
  const { isLaptopUp } = useViewport();
  const { soulmateId: currentUserSoulmateId } = useAtomValue(currentUserAtom);

  // Check if the handle starts with '@'.
  const soulmateId = handle.substring(1);
  const isCurrentUser = soulmateId === currentUserSoulmateId;

  const { data: progress } = useGetSoulmateProgressQuery({
    soulmateId,
    enabled: !!soulmateId,
  });

  const { data: soulmate } = useGetSoulmateQuery({
    soulmateId,
    enabled: !!soulmateId && progress?.status === SoulmateStatus.Completed,
  });

  const showShareDialog = useDialog(ShareDialog);
  const showBeautifyDialog = useDialog(ConnectSocialsDialog);
  const showMemeifyDialog = useDialog(MemeifyDialog);
  const showDiscoverDialog = useDialog(DiscoverDialog);
  const showChatDialog = useDialog(ChatDialog);

  return (
    <Container>
      {soulmate && !isCurrentUser && (
        <Content>
          <Button $size="large" $variant="secondary" $round onClick={() => showShareDialog({ soulmate })}>
            <Svg name="share" />
          </Button>

          <Button $size="large" $variant="secondary" $round onClick={() => showMemeifyDialog({})}>
            <Svg name="memeify" />
          </Button>
        </Content>
      )}

      {soulmate && isCurrentUser && (
        <Content>
          <OnboardingPopover
            imageUrl={onboardingStepOneUrl}
            title="Ready to make your AI Bae the next internet sensation?"
            step={1}
            side={isLaptopUp ? 'right' : 'top'}
            trigger={<div />}
          />

          <OnboardingPopover
            imageUrl={onboardingStepTwoUrl}
            title="Share your Bae to break the internet!"
            subtitle="Drop some heat and watch the likes skyrocket"
            step={2}
            side={isLaptopUp ? 'right' : 'top'}
            trigger={
              <Button $size="large" $variant="secondary" $round onClick={() => showShareDialog({ soulmate })}>
                <Svg name="share" />
              </Button>
            }
          />

          <OnboardingPopover
            imageUrl={onboardingStepThreeUrl}
            title="Connect more socials to give your AI Bae a glow-up"
            subtitle="Let’s make them pop for the TL!"
            step={3}
            side={isLaptopUp ? 'right' : 'top'}
            trigger={
              <Button
                $size="large"
                $variant="secondary"
                $round
                onClick={() => showBeautifyDialog({ regenerate: true })}
              >
                <Svg name="spark" />
              </Button>
            }
          />

          <OnboardingPopover
            imageUrl={onboardingStepFourUrl}
            title="Create your own memes"
            subtitle="The meme possibilities are inifinite!"
            step={4}
            side={isLaptopUp ? 'right' : 'top'}
            trigger={
              <Button $size="large" $variant="secondary" $round onClick={() => showMemeifyDialog({})}>
                <Svg name="memeify" />
              </Button>
            }
          />

          <OnboardingPopover
            imageUrl={onboardingStepFiveUrl}
            title="Explore and get inspiration from other Baes!"
            subtitle="Memeify them or create a reaction video"
            step={5}
            side={isLaptopUp ? 'right' : 'top'}
            trigger={
              <Button $size="large" $variant="secondary" $round onClick={() => showDiscoverDialog({})}>
                <Svg name="discover" />
              </Button>
            }
          />

          <OnboardingPopover
            imageUrl={onboardingStepSixUrl}
            title="Don't miss out!"
            subtitle="Join the waitlist to be the first to chat with your AI Bae"
            step={6}
            side={isLaptopUp ? 'right' : 'top'}
            trigger={
              <Button $size="large" $variant="secondary" $round onClick={() => showChatDialog({})}>
                <Svg name="chat" />
              </Button>
            }
          />
        </Content>
      )}
    </Container>
  );
};
