import { zodResolver } from '@hookform/resolvers/zod';
import { animated } from '@react-spring/web';
import { FieldErrors, useForm, UseFormRegister } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';

import { useSignupForWaitlistMutation } from 'src/api/waitlist/query';
import backgroundUrl from 'src/assets/images/background.png';
import bannerUrl from 'src/assets/images/banner.png';
import { useFadeInAndSlideSpring } from 'src/assets/springs';
import { LogoPill } from 'src/components/content/LogoPill';
import { SocialIcons } from 'src/components/content/SocialIcons';
import { Field } from 'src/components/ui/Field';
import { Image } from 'src/components/ui/Image';
import { Button } from 'src/elements/Button';
import { FlexGroup } from 'src/elements/FlexGroup';
import { DisplayLarge, PreTitle, Text } from 'src/elements/Text';
import { Footer } from 'src/layouts/MainLayout/Footer';
import { useViewport } from 'src/utils/hooks/useViewport';

import { MusicButton } from './MusicButton';

// Load the next route to avoid long wait when lazy loading.
import './Success';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  padding-bottom: 16px;

  @media only screen and (min-width: 1032px) {
    padding: 64px;
  }
`;

const CardContainer = styled.div<{ $imageUrl: string }>`
  display: flex;
  flex: 1;
  background-image: url(${({ $imageUrl }) => $imageUrl}), ${({ theme }) => theme.colors.bgBrand};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 8px;
  border-radius: 24px;
  padding: 32px 8px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const ImageContainer = styled.div`
  width: 70%;
  height: 70%;

  @media only screen and (max-width: 1032px) {
    width: 80%;
    height: 80%;
  }
`;

const Header = styled(FlexGroup)`
  padding: 16px 8px 8px;
  justify-content: space-between;
`;

const schema = z.object({
  email: z.string().trim().email(),
});

type SignupFormSchema = z.infer<typeof schema>;

export const Signup = () => {
  const titleFadeAnimation = useFadeInAndSlideSpring({ delay: 0 });
  const { isLaptopUp } = useViewport();
  const navigate = useNavigate();

  const { mutateAsync: signupForWaitlist } = useSignupForWaitlistMutation();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SignupFormSchema>({
    mode: 'onTouched',
    resolver: zodResolver(schema),
    defaultValues: { email: '' },
  });

  const performSignup = async ({ email }: SignupFormSchema) => {
    try {
      await signupForWaitlist({ email, referral_link: window.location.href });

      navigate('/waitlist/success?email=' + encodeURIComponent(email));
    } catch {
      setError('email', { message: 'An error occurred. Please try again.' });
    }
  };

  return (
    <Container>
      <form style={{ display: 'flex', flex: 1 }} onSubmit={handleSubmit(performSignup)}>
        <Content>
          <Header $flex={0}>
            <MusicButton />

            <div style={{ display: 'flex' }}>
              <LogoPill />
            </div>

            <SocialIcons />
          </Header>

          {!isLaptopUp && (
            <CardContainer $imageUrl={backgroundUrl}>
              <ImageContainer as={animated.div} style={titleFadeAnimation}>
                <Image src={bannerUrl} alt="Banner" ratio={1} />
              </ImageContainer>

              <DisplayLarge as={animated.h1} style={titleFadeAnimation} $color="brandSecondary" $centered>
                SIGN UP FOR THE WAITLIST
              </DisplayLarge>

              <Text as={animated.span} style={titleFadeAnimation} $color="darkSecondary" $centered>
                Snag the best AI Bae matches before anyone else!
              </Text>

              <WaitlistForm errors={errors} isSubmitting={isSubmitting} isValid={isValid} register={register} />
            </CardContainer>
          )}

          {isLaptopUp && (
            <FlexGroup $vertical $flex={0} $gap={32}>
              <DisplayLarge as={animated.h1} style={titleFadeAnimation} $color="brandSecondary">
                SIGN UP FOR THE WAITLIST
              </DisplayLarge>

              <Text as={animated.span} style={titleFadeAnimation} $color="darkSecondary">
                Snag the best AI Bae matches before anyone else!
              </Text>

              <WaitlistForm errors={errors} isSubmitting={isSubmitting} isValid={isValid} register={register} />
            </FlexGroup>
          )}

          <div style={{ padding: 8 }}>
            <Footer />
          </div>
        </Content>
      </form>

      {isLaptopUp && (
        <CardContainer $imageUrl={backgroundUrl}>
          <ImageContainer as={animated.div} style={titleFadeAnimation}>
            <Image src={bannerUrl} alt="Banner" ratio={1} />
          </ImageContainer>
        </CardContainer>
      )}
    </Container>
  );
};

interface FormProps {
  errors: FieldErrors<SignupFormSchema>;
  isSubmitting: boolean;
  isValid: boolean;
  register: UseFormRegister<SignupFormSchema>;
}

export const WaitlistForm = ({ errors, isSubmitting, isValid, register }: FormProps) => {
  const textFadeAnimation = useFadeInAndSlideSpring({ delay: 110 });

  return (
    <FlexGroup $vertical $gap={12} as={animated.div} style={{ padding: 8, ...textFadeAnimation }}>
      <Field placeholder="Enter your email" label="Your email" error={errors.email} {...register('email')} />

      <Button type="submit" $size="large" $centered $fullwidth disabled={!isValid} $loading={isSubmitting}>
        RESERVE MY SPOT
      </Button>

      <FlexGroup $gap={4} style={{ justifyContent: 'center' }}>
        <PreTitle $size="bodyMedium" $color="darkPrimary">
          SIGNED UP BEFORE?
        </PreTitle>

        <Button type="submit" $variant="text">
          <PreTitle $size="bodyMedium" $color="brandSecondary">
            CHECK YOUR STATUS
          </PreTitle>
        </Button>
      </FlexGroup>
    </FlexGroup>
  );
};
