import { useAtom } from 'jotai';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDeleteSoulmateMutation } from 'src/api/soulmate/query';
import { Dialog, DialogContent } from 'src/components/ui/Dialog';
import { Button } from 'src/elements/Button';
import { Text } from 'src/elements/Text';
import { currentUserAtom } from 'src/utils/atoms/state';
import { useHideDialog } from 'src/utils/hooks/useDialog';
import { useTikTokAuth } from 'src/utils/hooks/useTikTokAuth';

export const DeleteDialog = () => {
  const hide = useHideDialog();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [{ soulmateId }, setCurrentUser] = useAtom(currentUserAtom);
  const { error: tikTokError, data, handleAuthClick } = useTikTokAuth();

  const { mutateAsync: deleteSoulmate, isPending } = useDeleteSoulmateMutation();

  useEffect(() => {
    if (tikTokError) {
      setError('An error occurred. Please try again.');
    }
  }, [tikTokError]);

  useEffect(() => {
    if (!data?.accessToken) {
      return;
    }

    deleteSoulmate({ accessToken: data.accessToken, soulmateId })
      .then(() => {
        setCurrentUser({ soulmateId: '' });
        navigate('/');
        hide();
      })
      .catch(() => {
        setError('An error occurred. Please try again.');
      });
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog>
      {() => (
        <DialogContent
          title="DELERE YOUR BAE"
          description="This action is irreversible. Are you sure you want to delete your AI Bae?"
          buttons={[
            <Button $size="large" $variant="text" onClick={hide}>
              CANCEL
            </Button>,
            <Button $size="large" $loading={isPending} $variant="delete" onClick={handleAuthClick}>
              DELETE
            </Button>,
          ]}
        >
          <Fragment>{error && <Text $color="error">{error}</Text>}</Fragment>
        </DialogContent>
      )}
    </Dialog>
  );
};
