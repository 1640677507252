import * as RadixDialog from '@radix-ui/react-dialog';
import { useSetAtom } from 'jotai';
import { Fragment, ReactNode } from 'react';
import { css, styled } from 'styled-components';

import { fadeIn, slideAndFade } from 'src/assets/keyframes';
import { animationTiming, boxShadow } from 'src/assets/mixins';
import { FlexGroup } from 'src/elements/FlexGroup';
import { IconButton } from 'src/elements/Icon';
import { Text, TitleMedium } from 'src/elements/Text';
import { activeDialogAtom } from 'src/utils/atoms/ui';

interface Props {
  children: (controls: { hide: () => void }) => ReactNode | ReactNode[];
  fullheight?: boolean;
}

const Content = styled(RadixDialog.Content)<{ $fullheight: boolean }>`
  width: 500px;
  background: ${({ theme }) => theme.colors.bgLight};
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 4px;
  left: calc(50% - 250px);
  z-index: 1;
  gap: 32px;
  padding: 32px;
  max-height: calc(100% - 8px);

  ${({ $fullheight }) => {
    if ($fullheight) {
      return css`
        min-height: calc(100% - 8px);
        overflow-y: auto;
      `;
    }
  }}

  animation: ${slideAndFade} 0.2s;
  will-change: transform, opacity;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.deviceWidths.tablet}px) {
      left: 4px;
      width: calc(100% - 8px);
      bottom: 4px;
      top: auto;
    }
  `}

  ${animationTiming}
  ${boxShadow}
`;

const Overlay = styled(RadixDialog.Overlay)`
  background-color: rgba(22, 23, 24, 0.4);
  position: fixed;
  inset: 0;
  z-index: 1;

  animation: ${fadeIn} 0.2s;
  will-change: opacity;

  ${animationTiming}
`;

const Title = styled(RadixDialog.Title)`
  //
`;

const Description = styled(RadixDialog.Description)`
  //
`;

const Close = styled(RadixDialog.Close)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Footer = styled.div`
  display: flex;
  gap: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightBorderDefault};
  padding: 6px;
  margin: 0 -32px -32px;
  justify-content: flex-end;
  align-items: center;
`;

export const Dialog = ({ children, fullheight = false }: Props) => {
  const setActiveDialog = useSetAtom(activeDialogAtom);

  const hide = () => {
    setActiveDialog({ Component: null, props: {} });
  };

  return (
    <RadixDialog.Root open>
      <RadixDialog.Portal>
        <Overlay onClick={hide} />

        <Content $fullheight={fullheight}>
          {children({ hide })}

          <Close asChild onClick={hide}>
            <IconButton name="close" label="Close dialog" $color="lightPrimary" />
          </Close>
        </Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

interface DialogContentProps {
  title: ReactNode;
  children: ReactNode | ReactNode[];
  description?: ReactNode;
  buttons?: ReactNode[];
}

export const DialogContent = ({ title, children, description, buttons }: DialogContentProps) => {
  return (
    <Fragment>
      <FlexGroup $vertical $gap={8} $flex={0} style={{ alignItems: 'center' }}>
        <TitleMedium as={Title} $color="brandSecondary">
          {title}
        </TitleMedium>

        {description && (
          <Text as={Description} $color="darkSecondary">
            {description}
          </Text>
        )}
      </FlexGroup>

      {children}

      {buttons && <Footer>{buttons}</Footer>}
    </Fragment>
  );
};
