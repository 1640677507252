import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { DialogProvider } from 'src/providers/DialogProvider';
import { waitlistMusicPlayingAtom } from 'src/utils/atoms/ui';

const WAITLIST_MUSIC_URL = '/static/whats-an-aibae.mp3';

const Container = styled.div`
  display: flex;
  min-height: 100dvh;
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WaitlistLayout = () => {
  const { pathname } = useLocation();
  const musicPlaying = useAtomValue(waitlistMusicPlayingAtom);
  const audio = useMemo(() => new Audio(WAITLIST_MUSIC_URL), []);

  useEffect(() => {
    audio.volume = 0.1;
    audio.loop = true;

    if (musicPlaying) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [audio, musicPlaying]);

  // Scroll to top on nav.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <DialogProvider />

      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};
